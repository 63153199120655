<template>
    <v-container>
        <v-container>
            <v-row v-if="!this.$vuetify.theme.dark" class="text-center mt-8">
                <v-col cols="12">                   
                    <v-img
                        :src="require('../assets/whhlogo.png')"
                        class="my-3"
                        contain
                        height="200"/>
                </v-col>
            </v-row>
        </v-container>

    <v-container>
        <v-row v-if="this.$vuetify.theme.dark" class="text-center mt-8">
            <v-img
                :src="require('../assets/darkmode.png')"
                class="my-3"
                contain
                height="200"/>
        </v-row>
    </v-container>

        <v-row class="justify-center">
            <v-col class="mt-16" cols="4">
                <v-form ref="form" v-model="valid" lazy-validation>

                    <!-- Username Field -->
                    <v-text-field v-if="!$store.state.isUserLoggedIn" v-model="login"
                        :rules="loginRules" label="Username" required></v-text-field>

                    <!-- Password Field -->
                    <v-text-field v-if="!$store.state.isUserLoggedIn" v-model="password"
                        :rules="passwordRules" label="Password" type="password" required></v-text-field>

                    <!-- Log In/Out Buttons -->
                    <v-row class="justify-center">
                        <v-btn v-if="!$store.state.isUserLoggedIn" :disabled="!valid" color="success" class="mr-4" @click="logInUser"> Log In </v-btn>
                        <v-btn v-if="$store.state.isUserLoggedIn" color="error" class="mr-4" @click="logOutUser"> Log Out </v-btn>
                        <v-btn :disabled="!valid" color="primary" class="mr-4" @click="darkMode"><v-icon medium>mdi-theme-light-dark</v-icon></v-btn> 
                    </v-row>

                    <!-- Successful Login Snackbar (activates when user clicks "SUBMIT" button) -->
                    <v-snackbar v-model="snackbarSuccess" shaped>
                        {{snackbarMessage}}

                        <template v-slot:action="{ attrs }">
                            <v-btn color="primary" text v-bind="attrs" @click="snackbarSuccess = false">Close</v-btn>
                        </template>
                    </v-snackbar>

                    <!-- Bad Login Snackbar (activates when user clicks "SUBMIT" button) -->
                    <v-snackbar v-model="snackbarUnsuccess">
                        {{snackbarMessage}} 

                        <template v-slot:action="{ attrs }">
                            <v-btn color="primary" text v-bind="attrs" @click="snackbarUnsuccess = false">Close</v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
            </v-col> 
        </v-row>
    </v-container>
</template>


<script>
    import AuthenticationService from '../services/AuthenticationService'
    export default {
        name: 'LogIn',

        data () {
            return {
                valid: true,
                login: '',
                loginRules: [
                    v => !!v || 'Name is required',
                ],
                password: '',
                passwordRules: [
                    v => !!v || 'Password is required',
                ],
                select: null,

                // Variables to fire snackbar upon successful/unsuccessful login
                snackbarSuccess: false,
                snackbarUnsuccess: false,
                snackbarMessage: ""
            }
        },

        methods: {
            async logInUser () {
                try {
                    const response = await AuthenticationService.login({
                        login: this.login,
                        crypted_password: this.password
                    })
                    this.$store.dispatch('setToken', response.data.token)
                    this.$store.dispatch('setUser', response.data.user)

                    // Snackbar settings (users are currently sent straight to associations page & snackbar isn't needed)
                    // this.snackbarMessage = "Login Successful! Have a great day!"
                    // this.snackbarSuccess = true

                    // Automatically route user to Associations page after successful login
                    this.$router.push({ path: '/associations/associations' });

                } catch (error) {
                    this.error = error.response.data.error

                    // Snackbar Settings
                    this.snackbarMessage = "Login Unsuccessful...Please try again."
                    this.snackbarUnsuccess = true    // Snackbar Bad login message
                }
            },

            async logOutUser () {
                this.$store.dispatch('setToken', null)
                this.$store.dispatch('setUser', null)

                // Snackbar Settings
                this.snackbarMessage = "Logout Successful!  Have a great day!"
                this.snackbarSuccess = true
            },

            darkMode () {
                if(this.$vuetify.theme.dark == true) { 
                    this.$vuetify.theme.dark = false; 
                    console.log(this.$vuetify.theme.themes.dark.body);
                    // this.$vuetify.theme.themes.dark.body
                } 
                else { this.$vuetify.theme.dark = true; }
            },
        },
    }

</script>
